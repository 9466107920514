@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(./vendor/fonts/gotham.css);

* {
    font-family: 'Gotham', Helvetica, Arial, sans-serif;
}

.scroll_content::-webkit-scrollbar {
    width: 10px;
}

.scroll_content::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    border-radius: 100px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
