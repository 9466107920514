@font-face {
    font-family: 'Gotham';
    src: local('Gotham Book'), local('Gotham-Book'), url('Gotham-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold'), local('Gotham-Bold'), url('Gotham-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham Medium'), local('Gotham-Medium'), url('Gotham-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham Black'), local('Gotham-Black'), url('Gotham-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
