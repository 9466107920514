.ag-theme-alpine {
    --ag-header-background-color: #f7f7f7;
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-font-size: 13px;
    --ag-border-radius: 10px;
    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 50%;
    --ag-header-column-separator-width: 1px;

    --ag-borders-row: solid 1px;
    --ag-row-border-color: #59499a40;
}

.ag-input-field-input {
    color: #000 !important;
}

.ag-theme-alpine .ag-icon-menu,
.ag-icon-expanded,
.ag-icon-contracted {
    color: #fff;
}

.ag-theme-alpine .ag-cell,
.ag-paging-panel {
    font-weight: 700;
}

.ag-theme-alpine .ag-paging-panel {
    min-height: 55px;
}

.ag-header-cell-text {
    font-size: 12px;
    overflow: visible !important;
    text-overflow: inherit !important;
}
